export const isValidUrl = (urlString) => {
  if (!urlString || typeof urlString !== 'string') {
    return false;
  }

  try {
    const url = new URL(urlString);

    if (!url.protocol || !['http:', 'https:'].includes(url.protocol)) {
      return false;
    }

    if (!url.hostname || !url.hostname.includes('.')) {
      return false;
    }

    const hostnameRegex = /^[a-zA-Z0-9-_.]+\.[a-zA-Z]{2,}$/;
    if (!hostnameRegex.test(url.hostname)) {
      return false;
    }

    const urlPattern =
      /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,})([/\w .-]*)*\/?$/;
    if (!urlPattern.test(urlString)) {
      return false;
    }

    return true;
  } catch (error) {
    return false;
  }
};
